import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  allCartList: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
  allWishlistData: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
  allCouponList: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
  isLoadingCart: false,
  isLoadingWishlist: false,
  isApplyingCoupon: false,
};

export const cartWishlistSlice = createSlice({
  name: "cartWishlistData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingCart: (state, action) => {
      state.isLoadingCart = action.payload;
    },
    setLoadingWishlist: (state, action) => {
      state.isLoadingWishlist = action.payload;
    },
    setLoadingApplyCoupon: (state, action) => {
      state.isApplyingCoupon = action.payload;
    },
    setCartListing: (state, action) => {
      state.allCartList.items = action.payload;
      state.allCartList.error = {
        isError: false,
        message: "",
      };
    },
    setCartListingLoading: (state, action) => {
      state.allCartList.loading = action.payload;
    },
    setCartListingError: (state, action) => {
      state.allCartList.items = {};
      state.allCartList.error = action.payload;
    },
    setCouponListing: (state, action) => {
      state.allCouponList.items = action.payload;
      state.allCouponList.error = {
        isError: false,
        message: "",
      };
    },
    setCouponListingLoading: (state, action) => {
      state.allCouponList.loading = action.payload;
    },
    setCouponListingError: (state, action) => {
      state.allCouponList.items = {};
      state.allCouponList.error = action.payload;
    },
    setWishlistListing: (state, action) => {
      state.allWishlistData.items = action.payload;
      state.allWishlistData.error = {
        isError: false,
        message: "",
      };
    },
    setWishlistListingLoading: (state, action) => {
      state.allWishlistData.loading = action.payload;
    },
    setWishlistListingError: (state, action) => {
      state.allWishlistData.items = {};
      state.allWishlistData.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setLoadingCart,
  setLoadingWishlist,
  setLoadingApplyCoupon,
  setCartListing,
  setCartListingLoading,
  setCartListingError,
  setCouponListing,
  setCouponListingLoading,
  setCouponListingError,
  setWishlistListing,
  setWishlistListingLoading,
  setWishlistListingError,
} = cartWishlistSlice.actions;

export default cartWishlistSlice.reducer;

export const cartItemsSelector = (state) => state.cartWishlistData;
