import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  singleOrderData: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
  allOrderList: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
};

export const orderDataSlice = createSlice({
  name: "orderData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSingleOrderListing: (state, action) => {
      state.singleOrderData.items = action.payload;
      state.singleOrderData.error = {
        isError: false,
        message: "",
      };
    },
    setSingleOrderListingLoading: (state, action) => {
      state.singleOrderData.loading = action.payload;
    },
    setSingleOrderListingError: (state, action) => {
      state.singleOrderData.items = {};
      state.singleOrderData.error = action.payload;
    },
    setAllOrdersList: (state, action) => {
      state.allOrderList.items = action.payload;
      state.allOrderList.error = {
        isError: false,
        message: "",
      };
    },
    setAllOrdersListLoading: (state, action) => {
      state.allOrderList.loading = action.payload;
    },
    setAllOrdersListError: (state, action) => {
      state.allOrderList.items = {};
      state.allOrderList.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setSingleOrderListing,
  setSingleOrderListingLoading,
  setSingleOrderListingError,
  setAllOrdersList,
  setAllOrdersListLoading,
  setAllOrdersListError,
} = orderDataSlice.actions;

export default orderDataSlice.reducer;

export const orderItemsSelector = (state) => state.orderData;
