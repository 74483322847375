import { createContext, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  clearStorage,
  getItemFromStorage,
  setItemInStorage,
} from "../storage/Storage";
import { useEffect } from "react";
import { AddToWishlist } from "../Services/wishlist";
import { useDispatch } from "react-redux";
import { setLoadingWishlist } from "../redux/cartWishlist";
import { setNavigationData } from "../redux/navigation";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const token = localStorage?.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // call this function when you want to authenticate the user
  const login = async (userData, apptoken) => {
    try {
      // let prevLoc = getItemFromStorage("prev_loc");
      let afterLogin = getItemFromStorage("afterLogin");
      let afterLoginFunctionality = JSON.parse(afterLogin || "");
      setUser(userData);
      setItemInStorage("token", apptoken);
      if (afterLoginFunctionality?.type === "navigate") {
        navigate(`${afterLoginFunctionality?.to}`);
        if (
          afterLoginFunctionality?.bottomNav &&
          afterLoginFunctionality?.bottomNav?.hasButton === true
        ) {
          dispatch(
            setNavigationData({
              name: afterLoginFunctionality?.bottomNav?.name,
              id: afterLoginFunctionality?.bottomNav?.id,
            })
          );
        }
      } else if (afterLoginFunctionality?.type === "wishlist") {
        dispatch(setLoadingWishlist(true));
        const user = localStorage?.getItem("user");
        const userData = JSON.parse(user || "{}");
        const payload = {
          userId: userData?._id,
        };
        const response = await AddToWishlist(
          afterLoginFunctionality?.productData,
          payload
        );
        dispatch(setLoadingWishlist(false));
      }
      // if (prevLoc) {
      //   navigate(`${prevLoc}`);
      // } else {
      //   navigate("/");
      // }
    } catch (e) {}
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    localStorage?.setItem("token", null);
    clearStorage();
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      token,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
