import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingNavigation: false,
  navigationData: {},
};

export const navigationDataSlice = createSlice({
  name: "navigationData",
  initialState,
  reducers: {
    setLoadingNavigation: (state, action) => {
      state.loadingNavigation = action.payload;
    },
    setNavigationData: (state, action) => {
      state.navigationData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingNavigation, setNavigationData } =
  navigationDataSlice.actions;

export default navigationDataSlice.reducer;

export const navigationDataSelector = (state) => state.navigationData;
