import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  user: {
    error: {
      isError: false,
      message: "",
    },
    userDetails: null,
    userMenu: null,
    loading: false,
  },
  addressList: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
  openMobileLoginFlow: false,
  openDesktopLoginFlow: false,
};

export const loginSlice = createSlice({
  name: "loginUserData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserDetails: (state, action) => {
      state.user.userDetails = action.payload;
      state.user.error = {
        isError: false,
        message: "",
      };
    },
    setUserDetailsError: (state, action) => {
      state.user.userDetails = null;
      state.user.error = action.payload;
    },
    setUserDetailsLoading: (state, action) => {
      state.user.loading = action.payload;
    },
    setUserMenus: (state, action) => {
      state.user.userMenu = action.payload;
    },
    setOpenDesktopLoginFlow: (state, action) => {
      if (action.payload === true) {
        const userToken = localStorage?.getItem("token");
        if (
          userToken &&
          userToken?.length > 0 &&
          userToken !== null &&
          userToken !== undefined &&
          userToken !== "null" &&
          userToken !== "undefined"
        ) {
          state.openDesktopLoginFlow = false;
        } else {
          state.openDesktopLoginFlow = action.payload;
        }
      } else {
        state.openMobileLoginFlow = action.payload;
        state.openDesktopLoginFlow = action.payload;
      }
    },
    setOpenMobileLoginFlow: (state, action) => {
      if (action.payload === true) {
        const userToken = localStorage?.getItem("token");
        if (
          userToken &&
          userToken?.length > 0 &&
          userToken !== null &&
          userToken !== undefined &&
          userToken !== "null" &&
          userToken !== "undefined"
        ) {
          state.openMobileLoginFlow = false;
        } else {
          state.openMobileLoginFlow = action.payload;
        }
      } else {
        state.openMobileLoginFlow = action.payload;
        state.openDesktopLoginFlow = action.payload;
      }
    },
    setAddressList: (state, action) => {
      state.addressList.items = action.payload;
      state.addressList.error = {
        isError: false,
        message: "",
      };
    },
    setAddressListLoading: (state, action) => {
      state.addressList.loading = action.payload;
    },
    setAddressListError: (state, action) => {
      state.addressList.items = {};
      state.addressList.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setUserDetails,
  setUserDetailsError,
  setUserDetailsLoading,
  setUserMenus,
  setOpenDesktopLoginFlow,
  setOpenMobileLoginFlow,
  setAddressList,
  setAddressListLoading,
  setAddressListError,
} = loginSlice.actions;

export default loginSlice.reducer;

export const loginItemsSelector = (state) => state.loginUserData;
