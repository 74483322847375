import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { theme } from "./config/mui-theme";
import "./Assets/css/common.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthProvider } from "./Context/AuthProvider";
// import Route from "./route";
import AuthRoute from "./Routes/AuthRoute";
import "./AxiosInterceptor";
import "react-phone-input-2/lib/material.css";

// axios.interceptors.request.use(
//   async (config) => {
//     let request = config;
//     let token = ApiConfig.token;
//     if (!token) {
//       token = localStorage.getItem("token");
//     }
//     request.headers = token
//       ? {
//           Authorization: `${token}`,
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         }
//       : {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         };
//     request.url = configureUrl(config.url);
//     return request;
//   },
//   (error) => {
//     throw error.response;
//   }
// );

const App = () => {
  return (
    <>
      {/* <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Route />
          </Router>
        </Provider>
      </ThemeProvider> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <AuthProvider>
            <AuthRoute />
          </AuthProvider>
        </Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
