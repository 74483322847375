import ApiConfig from "../config/ApiConfig";
import {
  setLoading,
  setUserDetails,
  setUserDetailsError,
} from "../redux/login";
import { setItemInStorage } from "../storage/Storage";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { errorMessage } from "../lib/helper";
import { mainAxiosInstance } from "../AxiosInterceptor";
import axios from "axios";
import { STATUS_200, STATUS_300 } from "../data/constants";

// import { STATUS_200, STATUS_300 } from '../data/constants';
// import { sideMenu } from '../data/SideMenu';
// import { setAllMenuListing } from '../redux/userMenus';

const USER_INITIAL_DATA = {
  username: "",
  email: "",
  mobile: "",
  age: "",
  gender: "",
};

export const AddToWishlist = async (productId, payload) => {
  if (productId !== undefined && payload !== undefined) {
    try {
      const response = await mainAxiosInstance.put(
        `${ApiConfig.commonAddToWishlist}/${productId}`,
        {
          payload,
        }
      );
      // if (
      //   response?.data?.status >= STATUS_200 &&
      //   response?.data?.status < STATUS_300
      // ) {
      //   debugger;
      //   toast.success(response?.data?.msg, {
      //     autoClose: 2000,
      //   });
      //   return true;
      // } else {
      //   return false;
      // }
      if (
        response?.data?.status >= STATUS_200 &&
        response?.data?.status < STATUS_300
      ) {
        toast.success(response?.data?.message);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      toast.error(errorMessage(e));
      return false;
    }
  }
};

export const loginUser = (payload, setLoginStatusFlow) => {
  if (payload !== undefined) {
    // const response = await mainAxiosInstance.post(`${ApiConfig.login}`, payload);
    // return response;
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await mainAxiosInstance.post(
          `${ApiConfig.login}`,
          payload
        );
        if (resp?.data?.status >= 200 && resp?.data?.status < 300) {
          dispatch(setLoading(false));
          toast.success(resp.data.message);
          setLoginStatusFlow("2");
          return true;
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setUserDetailsError({
            isError: true,
            message: errorMessage(e),
          })
        );
        dispatch(setLoading(false));
        toast.error(errorMessage(e));
        return false;
      }
    };
  }
};


