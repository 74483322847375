import React, { useEffect, useState } from "react";
import { useAuth } from "../Context/AuthProvider";
import AllRoutes from "./Routes";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
// import { appSettingApi } from '../Services/commonService';
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../redux/login";
import { commonItemsSelector } from "../redux/common";
import {
  getItemFromStorage,
  getObjectFromStore,
  setItemInStorage,
} from "../storage/Storage";
// import { setAllMenuListing } from '../redux/userMenus';
import AppRoutes from "./Routes";
import { generateUUID } from "../lib/helper";

const AuthRoute = () => {
  const { user, token } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isMaintenance, setIsMaintenance] = useState(false);
  // const { appSetting } = useSelector(commonItemsSelector);
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(()=>{
  //   dispatch(appSettingApi())
  // },[])
  // useEffect(()=>{
  //   dispatch(setUserDetails(user));
  //   // const setUserMenusData = async() =>{
  //   //   if(user){
  //   //     let menuData = await getObjectFromStore('userMenu');
  //   //     if(menuData?.length){
  //   //       dispatch(setAllMenuListing(menuData));
  //   //     }
  //   //   }
  //   // }
  //   // setUserMenusData()
  // },[user])

  // useEffect(() => {
  //   if(process.env.REACT_APP_ENV == 'prod'){
  //     if(appSetting?.items?.features?.website_status[0]?.key_value == "1"){
  //       setIsMaintenance(true)
  //     }else{
  //       setIsMaintenance(false)
  //       if(isMaintenance){
  //         navigate('/dashboard')
  //       }
  //     }
  //   } else{
  //     setIsMaintenance(false)
  //     if(isMaintenance){
  //       navigate('/dashboard')
  //     }
  //   }
  // }, [appSetting]);
  const generateUniqueId = () => {
    let timestamp = new Date().getTime();
    let characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 10; i++) {
      let randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    let uniqueId = timestamp.toString() + "-" + result.toString();
    return uniqueId;
  };

  useEffect(() => {
    try {
      if (location) {
        setItemInStorage("prev_loc", location.pathname);
      }
    } catch (e) {}
  }, [location]);

  useEffect(() => {
    try {
      if (location) {
        const uniqueUserId = getItemFromStorage("uid");
        if (!uniqueUserId) {
          const generatedUniqueUserId = generateUUID();
          setItemInStorage("uid", generatedUniqueUserId);
        }
      }
    } catch (e) {}
  }, [location]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          // backgroundColor: theme.backgroundColor.box.primary,
          height: "100%",
          position: "fixed",
          width: "100%",
          zIndex: -10,
        }}
      ></Box>
      <AppRoutes user={user} token={token} isMaintenance={isMaintenance} />
    </Box>
  );
};

export default AuthRoute;
