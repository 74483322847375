import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  appSetting: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  stateData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    isStateAvailable: false,
    loading: false,
  },
  countryData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
  jobData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
};

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAppSetting: (state, action) => {
      state.appSetting.items = action.payload;
      state.appSetting.error = {
        isError: false,
        message: '',
      };
    },
    setAppSettingError: (state, action) => {
      state.appSetting.items = {};
      state.appSetting.error = action.payload;
    },
    setJobData: (state, action) => {
      state.jobData.items = action.payload;
    },
    setJobDataError: (state, action) => {
      state.jobData.error = action.payload;
      state.jobData.items = [];
    },
    setCountryData: (state, action) => {
      state.countryData.items = action.payload;
    },
    setCountryDataError: (state, action) => {
      state.countryData.error = action.payload;
      state.countryData.items = [];
    },
    setStateData: (state, action) => {
      state.stateData.items = action.payload;
      state.stateData.error = {
        isError: false,
        message: '',
      };
    },
    setStateDataError: (state, action) => {
      state.stateData.items = [];
      state.stateData.error = action.payload;
      state.stateData.isStateAvailable = false;
    },
    setIsStateAvailable: (state, action) => {
      state.stateData.isStateAvailable = action.payload;
    },
  },
});

export default common.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAppSetting,
  setAppSettingError,
  setJobData,
  setJobDataError,
  setCountryData,
  setCountryDataError,
  setStateData,
  setStateDataError,
  setIsStateAvailable,
} = common.actions;

export const commonItemsSelector = (state) => state.commonData;
