import axios from "axios";
import ApiConfig from "./config/ApiConfig";
import {
  clearStorage,
  getObjectFromStore,
  storageKeys,
} from "./storage/Storage";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

export const mainAxiosInstance = axios.create({});

mainAxiosInstance.interceptors.request.use((request) =>
  requestHandler(request)
);

mainAxiosInstance.interceptors.response.use(
  (response) => {
    checkTokenIsValid(response);
    return response;
  },
  (error) => errorHandler(error)
);

const checkTokenIsValid = (response) => {
  if (response?.data?.status == 401) {
    window.location = "/login";
    clearStorage();
    toast.error(response.data.message);
  }
};

const requestHandler = async (request) => {
  let token = "";
  if (!token) {
    token = await getObjectFromStore(storageKeys.token);
  }
  if (
    token &&
    token?.length > 0 &&
    token !== null &&
    token !== undefined &&
    token !== "null" &&
    token !== "undefined"
  ) {
    request.headers = {
      Authorization: token ? `Bearer ${token}` : "",
      // 'X-CSCAPI-KEY': "RmtmRFFKZDg3Y0cyaTVMWlNpNHVYcjV2VWZ3OWZGWU9nRHlpYkJuNQ==",
      "Content-Type": "application/json",
      Accept: "application/json",
      ...request.headers,
    };
  } else {
    request.headers = {
      // 'X-CSCAPI-KEY': "RmtmRFFKZDg3Y0cyaTVMWlNpNHVYcjV2VWZ3OWZGWU9nRHlpYkJuNQ==",
      "Content-Type": "application/json",
      Accept: "application/json",
      ...request.headers,
    };
  }
  return request;
};

const errorHandler = async (error) => {
  console.warn("errorHandler", error.response);
  throw error.response;
};
